import React from 'react'

import Avatar from '../components/Avatar'
import Box from '../styled/Box'
import CoreValues from '../components/CoreValues'
import FancyHeader from '../components/FancyHeader'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import WorkBlurb from '../components/WorkBlurb'

const IndexPage = () => (
  <Layout>
    <SEO title="Drew Barontini" />
    <FancyHeader>
      <Box
        as="h1"
        display={{ _: 'none', md: 'block' }}
        textTransform="uppercase"
      >
        Hello!
      </Box>
    </FancyHeader>
    <Box display={{ md: 'flex' }} mb="l">
      <Box>
        <Avatar display={{ _: 'block', md: 'none' }} my="l" mx="auto" />
        <Box
          as="p"
          fontSize={{ _: 'base', md: 'h2' }}
          lineHeight={{ md: '1.2' }}
          mb="l"
        >
          For over 10 years, I've helped build digital products for a variety of
          organizations.
        </Box>
        <Box as="p" mb="l">
          I’m passionate about solving problems with design, programming, and
          productivity systems to deliver effective solutions. I work remotely
          in sunny Orlando, FL with my beautiful wife, son, step-daughter, and
          pug.
        </Box>
      </Box>
      <Avatar display={{ _: 'none', md: 'block' }} ml={{ md: 'l' }} />
    </Box>
    <Box pb="xl">
      <CoreValues />
    </Box>
    <Box pb="xl">
      <WorkBlurb />
    </Box>
  </Layout>
)

export default IndexPage
