import React from 'react'

const values = [
  {
    title: 'Solving Problems',
    description: `All meaningful work is driven through problem-solving. I love a challenge, and I’m at my best when working through a problem.`,
    icon: (
      <>
        <path d="M9 11l3 3L22 4" />
        <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11" />
      </>
    ),
  },
  {
    title: 'Asking Questions',
    description: `Solutions are really just answers to questions. I explore and inquire to reach a higher level of understanding.`,
    icon: (
      <>
        <circle cx="12" cy="12" r="10" />
        <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3M12 17h.01" />
      </>
    ),
  },
  {
    title: 'Transparent Communication',
    description: `I’m not abrasive, but I don’t hold my tongue. I offer my opinion, and I communicate transparently, honestly, and effectively.`,
    icon: <path d="M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z" />,
  },
  {
    title: 'Essentialism',
    description: `Essentialism is the pursuit of less to regain control of my choices in order to channel my time, energy, and effort into making the highest possible contribution toward the goals and activities that matter.`,
    icon: (
      <>
        <circle cx="11" cy="11" r="8" />
        <path d="M21 21l-4.35-4.35" />
      </>
    ),
  },
  {
    title: 'Deep Work',
    description: `Deep Work is the ability to focus without distraction on a cognitively demanding task to produce better results in less time.`,
    icon: (
      <>
        <path d="M3 18v-6a9 9 0 0118 0v6" />
        <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
      </>
    ),
  },
  {
    title: 'Effective Solutions',
    description: `An effective solution is efficient, thoughtful, and productive. I believe in distilling the solution down to its most essential component.`,
    icon: <path d="M12 20V10M18 20V4M6 20v-4" />,
  },
  {
    title: 'Following Through',
    description: `When I say I’m going to do something, I do it. I don’t make false promises, and I always do what I say.`,
    icon: <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />,
  },
  {
    title: 'Constant Improvement',
    description: `I am an ever-evolving experiment, continually evaluating and improving myself. Everything is an iteration. Start small, grow forever.`,
    icon: (
      <>
        <path d="M17 1l4 4-4 4" />
        <path d="M3 11V9a4 4 0 014-4h14M7 23l-4-4 4-4" />
        <path d="M21 13v2a4 4 0 01-4 4H3" />
      </>
    ),
  },
]

export default values
