import React from 'react'

import podcast from '../images/podcast.png'
import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'

function WorkBlurb(props = {}) {
  return (
    <>
      <Box as="p" fontStyle="italic" mb={{ _: 'l', md: 'xl' }}>
        I currently work as...
      </Box>
      <Box as="h2">
        Product Director at{' '}
        <Box as="a" href="https://differential.com" textDecoration="none">
          Differential
        </Box>
      </Box>
      <Box as="p" mb="xl">
        I lead our Product Teams in building digital products for our client
        partners by developing their idea, validating it, and rapidly unlocking
        value in a continual de-risking process. We do this through Design
        Sprints, Development Sprints, and working in six-week Cycles against a
        tight, focused Roadmap built around continuous delivery and iteration.
      </Box>
      <FlexGrid
        alignItems="center"
        display={{ _: 'block', md: 'flex' }}
        gutter="l"
      >
        <FlexGrid.Item mb={{ _: 'l', md: '0' }}>
          <Box as="a" href="https://podcasts.apple.com/us/podcast/id1478302893">
            <Box
              as="img"
              src={podcast}
              size="200px"
              mx={{ _: 'auto', md: '0' }}
            />
          </Box>
        </FlexGrid.Item>
        <FlexGrid.Item flex="1">
          <Box as="h3">Product Conversations</Box>
          <Box fontSize="s" mr={{ _: '0', lg: 'xxl' }}>
            <Box as="p" mb="base">
              We finished the first season of the{' '}
              <Box
                as="a"
                href="https://podcasts.apple.com/us/podcast/id1478302893"
              >
                "Product Conversations"
              </Box>{' '}
              podcast where we discuss how great digital products are made.
            </Box>
            <Box as="p">Season two is coming soon&hellip;</Box>
          </Box>
        </FlexGrid.Item>
      </FlexGrid>
    </>
  )
}

export default WorkBlurb
