import React from 'react'
import PropTypes from 'prop-types'

import values from '../config/values'
import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'

function CoreValues(props = {}) {
  return (
    <>
      <Box as="p" fontStyle="italic" mb={{ _: 'l', md: 'xl' }}>
        I believe in...
      </Box>
      <Block start={1} />
      <Block start={3} />
      <Block start={5} />
      <Block start={7} />
    </>
  )
}

function Block(props = {}) {
  const index = props.start - 1
  const first = index
  const second = first + 1

  return (
    <FlexGrid
      gutter="xl"
      mb={{ md: 'xl' }}
      display={{ _: 'block', md: 'flex' }}
    >
      <FlexGrid.Item flexBasis="50%" mb={{ _: 'l', md: '0' }}>
        <Value {...values[first]} />
      </FlexGrid.Item>
      <FlexGrid.Item flexBasis="50%" mb={{ _: 'l', md: '0' }}>
        <Value {...values[second]} />
      </FlexGrid.Item>
    </FlexGrid>
  )
}

function Value(props = {}) {
  return (
    <>
      <Box color="medium" mb="base">
        <Icon children={props.icon} />
      </Box>
      <Box as="h2" fontSize="h3" textTransform="uppercase">
        {props.title}
      </Box>
      <Box as="p" fontSize="s">
        {props.description}
      </Box>
    </>
  )
}

function Icon({ children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  )
}

Block.propTypes = {
  start: PropTypes.number,
}

Value.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
}

Icon.propTypes = {
  children: PropTypes.element,
}

export default CoreValues
